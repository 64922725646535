const orderListItems = [
  {
    title: 'Name *',
    name: 'name',
    require: true,
  },
  {
    title: 'Email *',
    name: 'email_of_customer',
    require: true,
  },
  {
    title: 'Phone',
    name: 'phone',
    require: false,
  },
  {
    title: 'Company',
    name: 'company',
    require: true,
  },
  {
    title: 'Speed (m/min)',
    name: 'speed_of_conveyor',
    require: false,
  },
  {
    title: 'Belt Color',
    type: 'select',
    name: 'belt_color',
    values: ['Please select a color', 'Green', 'Black', 'Blue', 'White'],
    require: false,
  },
  {
    title: 'Side Guide Color',
    type: 'select',
    name: 'guard_rail_color',
    values: ['Please select a color', 'Green', 'Black', 'Blue', 'White'],
    require: false,
  },
  {
    title: 'Circumstance',
    name: 'circumstance',
    require: false,
  },
  {
    title: 'Product',
    name: 'product',
    require: false,
  },
  {
    title: 'Load',
    name: 'load',
    require: false,
  },
  {
    title: 'Advice / Offer? *',
    type: 'select',
    values: ['Please select a type', 'Advice', 'Offer'],
    name: 'advice_offer',
    require: true,
  },
  {
    title: 'Your reference',
    name: 'reference',
    require: false,
  },
  {
    title: 'Comments',
    name: 'comment',
    require: false,
    type: 'textarea',
  },
];

export default orderListItems;
