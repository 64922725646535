import React from 'react';
import { FormControl, InputGroup, Button, Form } from 'react-bootstrap';
// import Styles from '../styles.module.css';
import orderListItems from '../../../utils/orderListItems';
import { getShowGuardRailColor } from '../../../utils/getShowGuardRailColor';
import { sendOrderFetch } from '../../../api/allRequests';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const OrderList = ({
  setOrderListBool,
  parameters,
  model_name,
  setErrorModal,
  startConfiguration,
}) => {
  const modelsWithAnotherSpeedTitle = [
    'R100',
    'R150',
    'KDC',
    'KDZ',
    'VEHO',
    'BMK',
  ];
  modelsWithAnotherSpeedTitle.map((i) => {
    if (i === model_name) {
      orderListItems[4].title = 'Speed (m/min on center)';
    }
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      email_of_customer: '',
      phone: '',
      company: '',
      speed_of_conveyor: '',
      circumstance: '',
      product: '',
      load: '',
      advice_offer: '',
      guard_rail_color: '',
      belt_color: '',
      reference: '',
      comment: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('is Required'),
      advice_offer: Yup.string()
        .oneOf(['Advice', 'Offer'])
        .required('is Required'),
      email_of_customer: Yup.string()
        .email('invalid email')
        .required('is Required'),
      belt_color: Yup.string().oneOf(['Green', 'Black', 'Blue', 'White']),
    }),
    onSubmit: (values) =>
      sendOrderFetch(
        values,
        model_name,
        parameters,
        setOrderListBool,
        setErrorModal,
        startConfiguration,
      ),
  });
  return (
    <div>
      <div>
        <Form>
          {orderListItems.map((item, index) => {
            const isShowGuardRailColor = getShowGuardRailColor(
              model_name,
              item.name,
            );
            return (
              <Form.Group
                key={index}
                style={{
                  paddingTop: 15,
                  display: isShowGuardRailColor,
                }}
              >
                <InputGroup
                  size="sm"
                  hasValidation
                  style={{ padding: '0 7px' }}
                >
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    style={{
                      width: '166px',
                      height: item.type === 'textarea' ? '120px' : '21px',
                    }}
                  >
                    {item.title}
                  </InputGroup.Text>
                  {(function () {
                    switch (item.type) {
                      case 'select': {
                        return (
                          <Form.Select
                            isInvalid={formik.errors[item.name]}
                            style={{
                              width: '110px',
                              height: '21px',
                              padding: '0 10px',
                            }}
                            aria-label="Default select example"
                            name={item.name}
                            onChange={(e) => {
                              formik.setFieldValue(item.name, e.target.value);
                            }}
                            value={formik.values[item.name]}
                          >
                            {item.values.map((color, index) => {
                              if (item.name === 'guard_rail_color') {
                                if (
                                  (parameters.Guide_Type ===
                                    'GR50: 18mm, Alu, Spherical UHMPE' ||
                                    parameters.Guide_Type ===
                                    'GR55: 18mm, Alu, Flat UHMPE') &&
                                  color === 'White'
                                ) {
                                  return <option key={index}>White</option>;
                                } else if (
                                  parameters.Guide_Type ===
                                  'GR60: 40mm, Alu, Flat UHMPE' &&
                                  color !== 'White'
                                ) {
                                  return <option key={index}>{color}</option>;
                                }
                              } else {
                                return <option key={index}>{color}</option>;
                              }
                            })}
                          </Form.Select>
                        );
                      }
                      default: {
                        return (
                          <FormControl
                            as={item.type}
                            isInvalid={formik.errors[item.name]}
                            name={item.name}
                            style={{
                              width: '110px',
                              height: item.type ? '120px' : '21px',
                            }}
                            onChange={formik.handleChange}
                          // value={orderListState[item.name]}
                          />
                        );
                      }
                    }
                  })()}
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ margin: '0 0 7px 133px' }}
                  >
                    {formik.errors[item.name]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            );
          })}
        </Form>
      </div>
      <div
        style={{
          left: '20px',
          position: 'absolute',
          bottom: '-1%',
        }}
      >
        <Button
          variant="secondary"
          style={{
            width: '300px',
            marginBottom: '5px',
          }}
          onClick={() => formik.handleSubmit()}
        >
          Send
        </Button>
        <Button
          variant="secondary"
          style={{
            width: '300px',
            backgroundColor: 'black',
          }}
          onClick={() => setOrderListBool(true)}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default OrderList;
