const C83 = ({ parameters, values, model, field_name }) => {
  if (parameters.Length <= 650 && field_name === 'Support') {
    values = ['None'];
  }

  // if Guide bracket then Guide type
  if (parameters.Side_Bracket === 'None' && field_name === 'Guide type') {
    values = ['None'];
  }

  // if Support then Feet
  if (
    (parameters.Support === 'LT30' || parameters.Support === 'LT31') &&
    field_name === 'Feet / Wheel'
  ) {
    values = ['Feet', 'Wheel D75', 'Wheel D100'];
  }

  return values;
};

export default C83;
