import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import N60 from './Ilogic/N60';
import C83 from './Ilogic/C83';
import N114 from './Ilogic/N114';

const models = {
  N60,
  C83,
  N114,
};
export const SelectValidate = (props) => {
  const IlogicFunction = models[props.model];
  if (IlogicFunction) {
    const IlogicObject = IlogicFunction(props);
    return IlogicObject.map((valueItem) => (
      <option key={uuidv4()}>{valueItem}</option>
    ));
  }
  return props.values.map((valueItem) => (
    <option key={uuidv4()}>{valueItem}</option>
  ));
};

export const isDisabled = ({ type, dependence, name, limit }, parameters) => {
  if (parameters.Support === 'None') {
    if (name === 'Height' || name === 'Feet') return true;
  }
  switch (type) {
    case 'select': {
      if (dependence) {
        const { dependenceName } = dependence;
        return parameters[dependenceName];
      } else return false;
    }
    case 'limit': {
      if (dependence) {
        const { dependenceName } = dependence;
        if (!parameters[dependenceName]) {
          // parameters[name] = `${limit.defaultValue}`;
          return true;
        } else {
          return false;
        }
      } else return false;
    }
    default:
      return false;
  }
};
