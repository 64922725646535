import io from 'socket.io-client';
import { screenShot } from '../utils/viewerConfigs';
import { downloadFetch } from '../api/allRequests';

const { SERVER_HTTP_PROTOCOL, SERVER_HOST, SERVER_PORT } = process.env;

export function startWorkitem (format, fileName, setShowProgress, urn, setProgressValue, setOpenDownload, setErrorModal) {
  if (format === 'jpeg') {
    const { viewer } = window;
    const { clientWidth, clientHeight } = viewer;
    screenShot(viewer, clientWidth, clientHeight, fileName);
    setShowProgress(false);
    return;
  }
  const socket = io.connect(`${SERVER_HTTP_PROTOCOL}://${SERVER_HOST}:${SERVER_PORT}`, {
    transports: ['websocket'],
  });
  startConnection(function (connectionId) {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ format, urn, browerConnectionId: connectionId }),
    );
    downloadFetch(formData, setProgressValue, fileName, format, setOpenDownload, socket, setShowProgress, setErrorModal);
  });

  function startConnection (onReady) {
    console.log('socket', socket);
    if (socket && socket.connected) {
      if (onReady) {
        onReady(socket.id);
      } else return;
    }
    socket.on('connect', function () {
      onReady(socket.id);
    });

    socket.on('onComplete', function (message) {
      if (message === 'Model converted') {
        console.log('----Model converted');
        onReady(socket.id);
      }
      console.log('message socket', message);
      console.log('socket', socket);
    });
  }
}
