import { Modal, Button } from 'react-bootstrap';

const ModalMessage = ({
  messages,
  modalShow,
  setModalShow,
  setMessages,
  parameters,
  setParameters,
}) => {
  const messagesArr = Object.values(messages);

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {messagesArr.map((item, index) => {
          return (
            <ul key={index}>
              <li>{item}</li>
            </ul>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (messages.R100_inner_Radius) {
              setMessages({ R100_inner_Radius: messages.R100_inner_Radius });
            } else {
              setMessages({});
            }
            setModalShow(false);
            parameters.inputValueBool = true;
            setParameters(parameters);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMessage;
