// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_init_div__QUFab {\r\n  position: absolute;\r\n  width: calc(100% - 340px);\r\n  height: 100%;\r\n  right: 0;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Components/Viewer/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,QAAQ;AACV","sourcesContent":[".init_div {\r\n  position: absolute;\r\n  width: calc(100% - 340px);\r\n  height: 100%;\r\n  right: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"init_div": "styles_init_div__QUFab"
};
export default ___CSS_LOADER_EXPORT___;
