import { Modal, Button } from 'react-bootstrap';

const ModalMessage = () => {
  console.log('errorModal');
  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Error:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {'Something went wrong, please try again.'}
      </Modal.Body>
      <Modal.Footer>
        <Button
        variant="danger"
          onClick={() => window.location.reload()}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMessage;
