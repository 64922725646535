import React, { useContext, useEffect } from 'react';
import { Mycontext } from '../../../Context/context';
import ModelsParameters from '../../../ModelsParameters';
import Styles from '../styles.module.css';
import { Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { SelectValidate, isDisabled } from '../../../utils/Validate';

import ControlKey from '../../static/images/outline_keyboard.png';

const InputsChange = ({
  handleChange,
  startConfiguration,
  loading,
  parameters,
  setOpenDownload,
  setOrderListBool,
  setOpenGroup,
  openGroup,
}) => {
  const { model } = useContext(Mycontext);
  const set = new Set();
  const Inputs = ModelsParameters[model].map((item, index) => {
    set.add(item.groupDependancy);
    const disabled = isDisabled(item, parameters);
    switch (item.type) {
      case 'select': {
        return (
          <div
            dependancy={item.groupDependancy}
            className={Styles.selectContainer}
            key={uuidv4()}
          >
            <p className={Styles.lable}>{item.field_name}</p>
            <select
              disabled={item.values.length === 1 || disabled}
              aria-label="Default select example"
              name={item.name}
              onChange={(e) => handleChange(e, item.type, item.field_name)}
              value={parameters[item.name]}
            >
              {Object.keys(parameters).length === 0 || (
                <SelectValidate
                  field_name={item.field_name}
                  values={item.values}
                  parameters={parameters}
                  model={model}
                />
              )}
            </select>
            {typeof item.values[0] === 'number' && (
              <p style={{ color: 'white' }}>
                {item.name === 'Angle' ? '(deg)' : '(mm)'}
              </p>
            )}
          </div>
        );
      }
      case 'limit': {
        return (
          <div
            className={Styles.selectContainer}
            key={index}
            dependancy={item.groupDependancy}
          >
            <p className={Styles.lable}>{item.field_name}</p>
            <input
              value={
                parameters.inputValueBool ? parameters[item.name] : undefined
              }
              name={item.name}
              type="number"
              step={5}
              onChange={(e) =>
                handleChange(e, item.type, item.field_name, item.limit)
              }
              defaultValue={item.limit.defaultValue}
              disabled={disabled}
            />
            <p style={{ color: 'white' }}>
              {item.name === 'Angle' ? '(deg)' : '(mm)'}
            </p>
          </div>
        );
      }
      case 'checkbox': {
        return (
          <div
            className={Styles.selectContainer}
            key={index}
            dependancy={item.groupDependancy}
          >
            <p className={Styles.lable}>{item.field_name}</p>
            <div className={Styles.check}>
              <input
                style={{ marginLeft: 40 }}
                name={item.name}
                type="checkbox"
                onChange={handleChange}
                defaultChecked={item.values}
                value={parameters[item.name]}
              />
            </div>
          </div>
        );
      }
      case 'readOnly': {
        return (
          <div
            className={Styles.selectContainer}
            key={index}
            dependancy={item.groupDependancy}
          >
            <p className={Styles.lable}>{item.field_name}</p>
            <input
              defaultValue={parameters[item.name]}
              name={item.name}
              type="number"
              disabled={true}
            />
            <p style={{ color: 'white' }}>
              {item.name === 'Angle' ? '(deg)' : '(mm)'}
            </p>
          </div>
        );
      }
      default: {
        return [];
      }
    }
  });
  const groups = [...set];
  useEffect(() => {
    parameters.inputValueBool = true;
    const temp = {};
    groups.forEach((i) => {
      temp[i] = true;
    });
    setOpenGroup(temp);
  }, []);
  return (
    <div className={Styles.configContainer}>
      {groups.map((groupName, index) => {
        return (
          <div className="w-100 mt-3" key={index}>
            <div
              className={Styles.group}
              onClick={() => {
                const temp = {
                  ...openGroup,
                  [groupName]: !openGroup[groupName],
                };
                setOpenGroup(temp);
              }}
            >
              <p>{groupName}</p>
              <img
                src={ControlKey}
                alt=""
                style={{ rotate: openGroup[groupName] ? '180deg' : '' }}
              />
            </div>
            <div
              className={Styles.InputsContainer}
              style={{ display: openGroup[groupName] ? 'flex' : 'none' }}
            >
              {Inputs.filter((item) => item.props.dependancy === groupName)}
            </div>
          </div>
        );
      })}
      <div
        style={{
          left: 0,
          right: 0,
          position: 'absolute',
          bottom: '-1%',
          margin: 'auto',
          width: '95%',
        }}
      >
        <Button
          disabled={loading}
          variant="dark"
          style={{
            width: '100%',
            marginBottom: '5px',
          }}
          onClick={() => startConfiguration()()}
        >
          Configurate
        </Button>
        <Button
          variant="secondary"
          style={{
            width: '100%',
            marginBottom: '5px',
          }}
          onClick={() => setOpenDownload(false)}
        >
          Download
        </Button>
        <Button
          variant="secondary"
          style={{
            width: '100%',
          }}
          onClick={() => setOrderListBool(false)}
        >
          Advice/Offer
        </Button>
      </div>
    </div>
  );
};

export default InputsChange;
