const model = [
  {
    field_name: 'Belt width',
    type: 'select',
    name: 'Width',
    values: [
      200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900,
      950, 1000, 1050, 1100, 1150, 1200,
    ],
    defaultValue: 500,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Length center to center',
    type: 'limit',
    name: 'Length',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 1500,
      minValue: 350,
      maxValue: 45000,
      messages: {
        min: 'The minimum length is 350mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 30000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Motor Type',
    type: 'select',
    name: 'Motor_Type',
    values: ['MR10 (standard)', 'MR60 (SEW)'],
    defaultValue: 'MR10 (standard)',
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Motor position',
    type: 'select',
    name: 'Drive_unit',
    values: ['D10 Left', 'D10 Right', 'D20 Left', 'D20 Right'],
    defaultValue: 'D10 Left',
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Support',
    type: 'select',
    name: 'Support',
    values: ['None', 'LT22', 'LT24'],
    defaultValue: 'None',
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 320,
      maxValue: 15000,
      messages: {
        min: 'The minimum height is 900mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 9000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Feet / Wheel',
    type: 'select',
    name: 'Feet',
    values: ['Feet', 'Feet with Bracket', 'Wheel D75', 'Wheel D100'],
    defaultValue: 'Feet',
    groupDependancy: 'Support',
  },
  {
    field_name: 'Guide bracket',
    type: 'select',
    name: 'Side_Bracket',
    values: ['None', 'C83 GMB10', 'C83 GMB20', 'C83 GMB30'],
    defaultValue: 'None',
    groupDependancy: 'Side Guide',
  },
  {
    field_name: 'Guide type',
    type: 'select',
    name: 'Guide_Type',
    values: [
      'None',
      'GR50: 18mm, Alu, Spherical UHMPE',
      'GR55: 18mm, Alu, Flat UHMPE',
      'GR60: 40mm, Alu, Flat UHMPE',
    ],
    defaultValue: 'None',
    groupDependancy: 'Side Guide',
  },
];

export default model;
