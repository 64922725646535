export default function correctOrder(values) {
  if (values.parameters.Support === 'None') {
    delete values.parameters.Height;
  }

  // if (values.model_name === 'R100' || values.model_name === 'R150') {
  //   values.parameters.Drive_Unit_Inside = 'Drive Unit Inside';
  // }

  delete values.parameters.Inner_radius;
  delete values.parameters.inputValueBool;
}
