const { THREE, Autodesk } = window;

export const loadExtension = (e, viewer) => {
  if (e.extensionId === 'Autodesk.BimWalk') {
    const navTools = viewer.toolbar.getControl('navTools');
    const modelTools = viewer.toolbar.getControl('modelTools');
    const settingsTools = viewer.toolbar.getControl('settingsTools');

    navTools.removeControl('toolbar-bimWalkTool');
    navTools.removeControl('toolbar-cameraSubmenuTool');
    modelTools.removeControl('toolbar-documentModels');
    modelTools.removeControl('toolbar-sectionTool');
    // modelTools.removeControl('toolbar-measurementSubmenuTool');
    modelTools.removeControl('toolbar-explodeTool');
    settingsTools.removeControl('toolbar-settingsTool');
    settingsTools.removeControl('toolbar-propertiesTool');
    settingsTools.removeControl('toolbar-modelStructureTool');
    settingsTools.removeControl('toolbar-fullscreenTool');

    // viewer.removeEventListener(
    //   Autodesk.Viewing.EXTENSION_LOADED_EVENT,
    //   onExtensionLoaded,
    // );
  }
};

export const screenShot = (viewer, w, h, fileName) => {
  const vw = viewer.container.clientWidth;
  const vh = viewer.container.clientHeight;
  if (w > vw || h > vh) {
    alert('Dimensions should not be larger than Viewer');
    return;
  }

  viewer.getScreenShot(vw, vh, (blob) => {
    const canvas = document.getElementById('MyCanvas');
    const ctx = canvas.getContext('2d');
    canvas.width = w;
    canvas.height = h;
    const image = new Image();
    image.onload = () => {
      ctx.clearRect(0, 0, w, h);
      const sx = (vw - w) / 2;
      const sy = (vh - h) / 2;
      ctx.drawImage(image, sx, sy, w, h, 0, 0, w, h);
    };
    image.src = blob;
    const link = document.createElement('a');
    link.href = blob;
    link.setAttribute('download', `${fileName}.jpeg`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
export const setCamera = (viewer, model) => {
  window.viewer = viewer;
  if (
    model === 'C83' ||
    model === 'N60' ||
    model === 'KDZ' ||
    model === 'N114'
  ) {
    const POSITION = new THREE.Vector3(2067, -4119, 3595);
    const TARGET = new THREE.Vector3(-0.0001, 2.7, -0.0007);
    const PIVOT = new THREE.Vector3(-0.0001, 9, -0.0007);
    const WORLDUP = new THREE.Vector3(0, 1, 0);
    const UP = new THREE.Vector3(-0.2, 0.5, 0.7);
    const fov = viewer.navigation.getHorizontalFov();
    viewer.navigation.setRequestTransitionWithUp(
      true,
      POSITION,
      TARGET,
      fov,
      UP,
      WORLDUP,
      PIVOT,
    );
    setTimeout(() => {
      viewer.autocam.setHomeViewFrom(viewer.navigation.getCamera());
    }, 1000);
  }
  if (model === 'KDZ') {
    const POSITION = new THREE.Vector3(-2386, 3560, 1667);
    const TARGET = new THREE.Vector3(-59.5, -109, 141);
    const PIVOT = new THREE.Vector3(-9.5, -68.4, 218);
    const WORLDUP = new THREE.Vector3(0, 1, 0);
    const UP = new THREE.Vector3(0.1, -0.3, 0.9);
    const fov = viewer.navigation.getHorizontalFov();
    viewer.navigation.setRequestTransitionWithUp(
      true,
      POSITION,
      TARGET,
      fov,
      UP,
      WORLDUP,
      PIVOT,
    );
    setTimeout(() => {
      viewer.autocam.setHomeViewFrom(viewer.navigation.getCamera());
    }, 1000);
  }
  if (model === 'RD50') {
    const POSITION = new THREE.Vector3(-2386, 3560, 1667);
    const TARGET = new THREE.Vector3(-59.5, -109, 141);
    const PIVOT = new THREE.Vector3(-76, -83, 151.8);
    const WORLDUP = new THREE.Vector3(0, 1, 0);
    const UP = new THREE.Vector3(0.1, -0.3, 0.9);
    const fov = viewer.navigation.getHorizontalFov();
    viewer.navigation.setRequestTransition(
      true,
      POSITION,
      TARGET,
      fov,
      UP,
      WORLDUP,
      PIVOT,
    );
    setTimeout(() => {
      viewer.autocam.setHomeViewFrom(viewer.navigation.getCamera());
    }, 1000);
  }
  if (model === 'VEHO') {
    const POSITION = new THREE.Vector3(290.3, 2839.8, 326.8);
    const TARGET = new THREE.Vector3(-41.2, -187.2, -22.4);
    const PIVOT = new THREE.Vector3(-20.3, 3.27, -0.42);
    const WORLDUP = new THREE.Vector3(0, 1, 0);
    const UP = new THREE.Vector3(-0.98, 0.08, 0.17);
    const fov = viewer.navigation.getHorizontalFov();
    viewer.navigation.setRequestTransitionWithUp(
      true,
      POSITION,
      TARGET,
      fov,
      UP,
      WORLDUP,
      PIVOT,
    );
    setTimeout(() => {
      viewer.autocam.setHomeViewFrom(viewer.navigation.getCamera());
    }, 1000);
  }
  if (model === 'R100' || model === 'R150') {
    // const camera = new Autodesk.Viewing.UnifiedCamera();

    const POSITION = new THREE.Vector3(3198, 3170, 3176);
    const TARGET = new THREE.Vector3(29, 0.8, 6.5);
    const PIVOT = new THREE.Vector3(29, 0.8, 6.5);
    const WORLDUP = new THREE.Vector3(0, 1, 0);
    const UP = new THREE.Vector3(-0.4, 0.8, -0.4);

    // camera.position.set(3198, 3170, 3176);
    // camera.target.set(29, 0.8, 6.5);
    // camera.worldup.set(0, 1, 0);
    // camera.up.set(-0.4, 0.8, -0.4);
    // camera.setFov(21);

    const fov = viewer.navigation.getVerticalFov();
    viewer.navigation.setRequestTransition(
      true,
      POSITION,
      TARGET,
      fov,
      UP,
      WORLDUP,
      PIVOT,
    );

    setTimeout(() => {
      viewer.autocam.setHomeViewFrom(viewer.navigation.getCamera());
    }, 2000);
  }
  if (model === 'RD50C') {
    const POSITION = new THREE.Vector3(331, 2557, 642);
    const TARGET = new THREE.Vector3(138, -171, 58);
    const PIVOT = new THREE.Vector3(-268, -126, -18);
    const WORLDUP = new THREE.Vector3(0, 1, 0);
    const UP = new THREE.Vector3(0.16, 0.19, -0.96);

    const fov = viewer.navigation.getVerticalFov();
    viewer.navigation.setRequestTransition(
      true,
      POSITION,
      TARGET,
      fov,
      UP,
      WORLDUP,
      PIVOT,
    );

    setTimeout(() => {
      viewer.autocam.setHomeViewFrom(viewer.navigation.getCamera());
    }, 2000);
  }
};
