import Styles from '../styles.module.css';
import { useContext, useState } from 'react';
import { Mycontext } from '../../../Context/context';
import { Button, Dropdown } from 'react-bootstrap';
import { startWorkitem } from '../../../utils/download';

import Placeholder from './Placeholder/Placeholder';

const Convert = ({ urn, setOpenDownload, setErrorModal }) => {
  const { fileName } = useContext(Mycontext);
  const [format, setFormat] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [progressValue, setProgressValue] = useState({
    valueStart: 0,
    valueEnd: 60,
    duration: 15,
  });

  return (
    <div className={Styles.formatChangeContainer}>
      <div
        className="justify-content-center flex-wrap"
        style={{ display: !showProgress ? 'none' : 'flex', width: '90%' }}
      >
        {showProgress && <Placeholder progressValue={progressValue} />}
      </div>
      <div
        className="justify-content-center flex-wrap"
        style={{ display: showProgress ? 'none' : 'flex' }}
      >
        <h4 className="mb-4" style={{ textAlign: 'center' }}>Please choose the format of configured model</h4>
        <Dropdown style={{ height: 'fit-content' }}>
          <Dropdown.Toggle
            variant="secondary"
            style={{ width: 150 }}
          >
            {format || 'Format'}
          </Dropdown.Toggle>

          <Dropdown.Menu variant="dark">
            <Dropdown.Item onClick={(e) => setFormat(e.target.text)}>
              stp
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => setFormat(e.target.text)}>
              sat
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => setFormat(e.target.text)}>
              dwg
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => setFormat(e.target.text)}>
              jpeg
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={Styles.buttonGroupContainer}
      >
        <Button className={Styles.downloadButton}
          disabled={!format}
          variant="secondary"
          onClick={() => {
            setShowProgress(true);
            startWorkitem(format, fileName, setShowProgress, urn, setProgressValue, setOpenDownload, setErrorModal);
          }}
        >
          Download
        </Button>
        <Button className={Styles.backButton}
          style={{
            width: '300px',
            backgroundColor: 'black',
          }}
          onClick={() => setOpenDownload(true)}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Convert;
