const checkLimitType = (
  name,
  value,
  limit,
  model,
  messages,
  parameters,
  field_name,
) => {
  value = Number(value);
  let limitMessage = { ...messages };
  const Outer_Radius_BMK = {};
  if (value < 750) {
    if (model === 'N60') {
      limitMessage = {
        ...messages,
        length750: 'With a length < 750mm support is only possible on request.',
      };
    }
    if (value < limit.minValue) {
      value = limit.minValue;
      limitMessage[name] = limit.messages.min;
    } else {
      delete limitMessage[name];
    }
  } else if (value > limit.maxValue) {
    value = limit.maxValue;
    limitMessage = { ...messages, [name]: limit.messages.max };
  } else {
    delete limitMessage[name];
    delete limitMessage.length750;
  }

  if (model === 'BMK') {
    if (field_name === 'Belt Width') {
      if (parameters.Outer_radius - value < 200) {
        Outer_Radius_BMK.Outer_radius =
          parameters.Outer_radius + (value - parameters.Outer_radius) + 200;
        delete limitMessage.Outer_radius_range;
        limitMessage.Outer_radius_range = `Your input was ${parameters.Outer_radius}, but the rules in the model have changed it to  ${Outer_Radius_BMK.Outer_radius}`;
      } else delete limitMessage.Outer_radius_range;
    }
    if (field_name === 'Outer Radius') {
      if (value - parameters.Belt_Width < 200) {
        const x = value - parameters.Belt_Width;
        Outer_Radius_BMK.Outer_radius = value + Math.abs(200 - x);
        // console.log('x', x);
        // console.log('----------', Math.abs(200 - x));
        delete limitMessage.Belt_Width_range;
        limitMessage.Outer_radius_range = `Your input was ${value}, but the rules in the model have changed it to ${parameters.Belt_Width + 200
          }`;
      } else delete limitMessage.Outer_radius_range;
    }
    // console.log('Outer_Radius_BMK', Outer_Radius_BMK);
  }

  if (model === 'R100') {
    const minMessage =
      'minimum value of inner radius is 300mm. Either decrease the belt width or increase the outer radius';
    if (name === 'Outer_Radius') {
      if (value - parameters.Belt_Width < 300) {
        limitMessage.R100_inner_Radius = minMessage;
      } else {
        delete limitMessage.R100_inner_Radius;
      }
    } else if (name === 'Belt_Width') {
      if (parameters.Outer_Radius - value < 300) {
        limitMessage.R100_inner_Radius = minMessage;
      } else {
        delete limitMessage.R100_inner_Radius;
      }
    }
  }

  if (model === 'N114') {
    if (
      (parameters.Support === 'LT42' || parameters.Support === 'LT44') &&
      field_name === 'Belt Width Manual'
    ) {
      if (value < 400) {
        parameters.previous = { BW_Manual_previous: value };
        value = 400;
        limitMessage.BW_Manual =
          'The minimum belt width is 400mm. The value will be automatically corrected to the minimum. ';
      }
    } else {
      // console.log('else');
      delete limitMessage.BW_Manual;
    }
  }
  return {
    limitMessage,
    paramValue: {
      [name]: Number(value),
      ...Outer_Radius_BMK,
      // previous: {
      //   BW_Manual_previous: parameters.previous?.BW_Manual_previous,
      // },
    },
  };
};

export default checkLimitType;
