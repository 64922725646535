const model = [
  {
    field_name: 'Angle (deg)',
    type: 'select',
    name: 'Angle',
    values: [30, 45, 60, 90, 180],
    defaultValue: 90,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Belt Width',
    type: 'limit',
    name: 'Belt_Width',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 600,
      minValue: 200,
      maxValue: 1600,
      messages: {
        min: 'The minimum belt width is 200mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum belt width is 1600mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Outer Radius',
    type: 'limit',
    name: 'Outer_radius',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 1200,
      minValue: 600,
      maxValue: 3500,
      messages: {
        min: 'The minimum Outer Radius is 600mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum Outer Radius is 3500mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Counter Clockwise',
    type: 'checkbox',
    name: 'Counter_Clockwise',
    values: false,
    groupDependancy: 'Motor Position',
  },
];

export default model;
