import { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ModelsParameters from '../ModelsParameters';

export const Mycontext = createContext();

export default function Context (props) {
  const [urn, setUrn] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpenErrorModal, setErrorModal] = useState(false);
  const [fileName, setFileName] = useState('');
  const location = useLocation();
  const path = location.pathname.slice(1);
  const modelsName = ModelsParameters[path] ? path : 'VEHO';
  const [model, setModel] = useState(modelsName);
  const [parameters, setParameters] = useState({});
  const [progressValue, setProgressValue] = useState({
    valueStart: 0,
    valueEnd: 40,
    duration: 40,
  });
  useEffect(() => {
    const temp = {};
    ModelsParameters[model].forEach((item) => {
      if (item.type === 'select') temp[item.name] = item.defaultValue;
      if (item.type === 'limit') { temp[item.name] = Number(item.limit.defaultValue); }
      if (item.type === 'checkbox') temp[item.name] = item.values;
      if (item.type === 'readOnly') temp[item.name] = item.value;
    });
    temp.inputValueBool = false;
    setParameters(temp);
  }, []);

  return (
  <Mycontext.Provider value={{
    setParameters,
    parameters,
    urn,
    setUrn,
    loading,
    setLoading,
    model,
    setModel,
    progressValue,
    setProgressValue,
    setFileName,
    fileName,
    isOpenErrorModal,
    setErrorModal,
  }}>
  {props.children}
  </Mycontext.Provider>
  );
}
