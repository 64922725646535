import CorrectOrder from '../utils/correctOrder';

const { SERVER_HTTP_PROTOCOL, SERVER_HOST, SERVER_PORT } = process.env;

export const initializeFetch = (
  accessToken,
  model,
  setUrn,
  setFileName,
  setErrorModal,
) => {
  fetch(
    `${SERVER_HTTP_PROTOCOL}://${SERVER_HOST}:${SERVER_PORT}/api/forge/fileinitialize/${model}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then((res) => res.json())
    .then((data) => {
      setUrn(data.urn);
      setFileName(data.fileName);
    })
    .catch(() => setErrorModal(true));
};

export const getAuthToken = (setAccessToken, setErrorModal) => {
  fetch(
    `${SERVER_HTTP_PROTOCOL}://${SERVER_HOST}:${SERVER_PORT}/api/forge/oauth/token`,
  )
    .then((res) => {
      res.json().then((data) => {
        setAccessToken(data.access_token);
      });
    })
    .catch(() => setErrorModal(true));
};

export const workItemFetch = (
  model,
  formData,
  setFileName,
  setProgressValue,
  setUrn,
  setLoading,
  setErrorModal,
) => {
  fetch(
    `${SERVER_HTTP_PROTOCOL}://${SERVER_HOST}:${SERVER_PORT}/api/forge/designautomation/workitems/${model}`,
    {
      method: 'POST',
      body: formData,
    },
  )
    .then((response) => response.json())
    .then((data) => {
      setFileName(data.fileName);
      if (data.urn) {
        setProgressValue({
          valueStart: 10,
          valueEnd: 100,
          duration: 1,
        });
        setTimeout(() => {
          setUrn(data.urn);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }, 500);
      }
    })
    .catch((err) => {
      console.log(err, 'err');
      setErrorModal(true);
      setLoading(false);
    });
};

export const downloadFetch = (
  formData,
  setProgressValue,
  fileName,
  format,
  setOpenDownload,
  socket,
  setShowProgress,
  setErrorModal,
) => {
  fetch(
    `${SERVER_HTTP_PROTOCOL}://${SERVER_HOST}:${SERVER_PORT}/api/forge/convertation/workitems`,
    {
      method: 'POST',
      body: formData,
    },
  )
    .then((response) => {
      if (!response.ok) throw response.statusText;
      if (response.status === 200) {
        setProgressValue({
          valueStart: 60,
          valueEnd: 99,
          duration: 10,
        });
        const blob = response.blob();
        return blob;
      }
    })
    .then((blob) => {
      // console.log("blob", blob);
      if (blob) {
        const href = window.URL.createObjectURL(blob);
        // console.log("href", href);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${fileName}.${format}`); // or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        socket.disconnect();
        setOpenDownload(true);
      }
    })
    .catch(() => {
      setErrorModal(true);
      setShowProgress(false);
    });
};

export const sendOrderFetch = (
  values,
  model_name,
  parameters,
  setOrderListBool,
  setErrorModal,
  startConfiguration,
) => {
  values.model_name = model_name;
  values.parameters = parameters;
  CorrectOrder(values);
  fetch(`${SERVER_HTTP_PROTOCOL}://${SERVER_HOST}:${SERVER_PORT}/api/order`, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      console.log('response', response);
      if (response.status === 200) {
        setOrderListBool(true);
      } else if (response.status === 202) {
        startConfiguration(values)();
      } else {
        return response.json();
      }
    })
    .catch(() => setErrorModal(true));
};
