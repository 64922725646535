const N60 = ({ parameters, values, model, field_name }) => {
  if (
    (parameters.Support === 'LT22' || parameters.Support === 'LT30') &&
    field_name === 'Belt width'
  ) {
    values = values.filter((item) => item >= 400);
  }
  if (parameters.Support === 'LT31' && field_name === 'Belt width') {
    values = values.filter((item) => item >= 250);
  }
  if (
    parameters.Motor_Type === 'MR70 (drum motor)' &&
    field_name === 'Belt width'
  ) {
    values = values.filter((item) => item >= 300);
  }
  if (
    parameters.Motor_Type === 'MR70 (drum motor)' &&
    field_name === 'Motor position'
  ) {
    values = ['None'];
  }
  if (parameters.Width < 250 && field_name === 'Support') {
    values = ['None', 'LT24'];
  }
  if (parameters.Width < 400 && field_name === 'Support') {
    values = ['None', 'LT24', 'LT31'];
  }
  if (parameters.Width < 300 && field_name === 'Motor type') {
    values = ['MR10 (standard)', 'MR60 (SEW)'];
  }
  if (
    (parameters.Side_Bracket === 'None' ||
      parameters.Side_Bracket === undefined) &&
    field_name === 'Guide type'
  ) {
    values = ['None'];
  }
  if (
    (parameters.Support === 'LT30' || parameters.Support === 'LT31') &&
    field_name === 'Feet / Wheel'
  ) {
    values = ['Feet', 'Wheel D75', 'Wheel D100'];
  }
  if (
    (parameters.Drive_unit === 'D10 Left' ||
      parameters.Drive_unit === 'D10 Right') &&
    field_name === 'Option input side'
  ) {
    values = ['None', 'Shortened Plate', 'Transfer Roll', 'Fixed Knife Edge'];
  }
  if (
    (parameters.Drive_unit === 'D10 Left' ||
      parameters.Drive_unit === 'D10 Right') &&
    field_name === 'Option output side'
  ) {
    values = ['None', 'Shortened Plate', 'Transfer Roll'];
  }
  if (
    (parameters.Drive_unit === 'D20 Left' ||
      parameters.Drive_unit === 'D20 Right') &&
    field_name === 'Option input side'
  ) {
    values = ['None', 'Shortened Plate', 'Transfer Roll', 'Fixed Knife Edge'];
  }
  if (
    (parameters.Drive_unit === 'D20 Left' ||
      parameters.Drive_unit === 'D20 Right') &&
    field_name === 'Option output side'
  ) {
    values = ['None', 'Transfer Roll'];
  }
  if (
    (parameters.Drive_unit === 'D20 Left' ||
      parameters.Drive_unit === 'D20 Right') &&
    field_name === 'Horizontal / Vertical'
  ) {
    values = ['Horizontal'];
  }
  if (
    parameters.Drive_unit === 'D30 Middle' &&
    field_name === 'Option output side'
  ) {
    values = ['None', 'Shortened Plate', 'Transfer Roll', 'Fixed Knife Edge'];
  }
  if (
    parameters.Drive_unit === 'D30 Middle' &&
    field_name === 'Option input side'
  ) {
    values = ['None', 'Shortened Plate', 'Transfer Roll', 'Fixed Knife Edge'];
  }
  if (
    parameters.Motor_Type === 'MR70 (drum motor)' &&
    field_name === 'Option input side'
  ) {
    values = ['None', 'Shortened Plate', 'Transfer Roll', 'Fixed Knife Edge'];
  }
  if (
    parameters.Motor_Type === 'MR70 (drum motor)' &&
    field_name === 'Option output side'
  ) {
    values = ['None'];
  }
  if (
    parameters.Motor_Type === 'MR70 (drum motor)' &&
    field_name === 'Horizontal / Vertical'
  ) {
    values = ['Horizontal'];
  }

  return values;
};

export default N60;
