const model = [
  {
    field_name: 'Belt Width',
    type: 'select',
    name: 'Belt_Width',
    values: [
      70, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000,
      1200,
    ],
    defaultValue: 500,
    groupDependancy: 'Dimensions',
    dependence: {
      dependenceName: 'BW_Manual_Input',
    },
  },
  {
    field_name: 'Belt Width Manual Input',
    type: 'checkbox',
    name: 'BW_Manual_Input',
    values: false,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Belt Width Manual',
    type: 'limit',
    name: 'BW_Manual',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 600,
      minValue: 1,
      maxValue: 1600,
      messages: {
        min: 'The minimum belt width is 1mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum belt width is 1600mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'BW_Manual_Input',
    },
  },
  {
    field_name: 'Length',
    type: 'limit',
    name: 'Length',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 2000,
      minValue: 550,
      maxValue: 30000,
      messages: {
        min: 'The minimum length is 550mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 30000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Motor position',
    type: 'select',
    name: 'Motor_Position',
    values: ['Left', 'Right'],
    defaultValue: 'Left',
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Support',
    type: 'select',
    name: 'Support',
    values: ['None', 'LT42', 'LT44'],
    defaultValue: 'None',
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 450,
      maxValue: 3000,
      messages: {
        min: 'The minimum height is 450mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 3000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Motor type',
    type: 'select',
    name: 'Drive_type',
    values: ['MR10 (standard)', 'MR60 (SEW)'],
    defaultValue: 'MR10 (standard)',
    groupDependancy: 'Drive Unit',
  },
];

export default model;
