const model = [
  {
    field_name: 'Angle (deg)',
    type: 'limit',
    name: 'Angle',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 90,
      minValue: 25,
      maxValue: 180,
      messages: {
        min: 'The minimum angle is 25deg. The value will be automatically corrected to the minimum. ',
        max: 'The maximum angle is 180deg. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Belt Width',
    type: 'limit',
    name: 'Beltwidth',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 600,
      minValue: 200,
      maxValue: 1100,
      messages: {
        min: 'The minimum belt width is 200mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum belt width is 1100mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Outside Radius',
    type: 'limit',
    name: 'Outside_Radius',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 1200,
      minValue: 300,
      maxValue: 3500,
      messages: {
        min: 'The minimum outside radius is 300mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum outside radius is 3500mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Motor type',
    type: 'select',
    name: 'Motor_Type',
    values: ['MR10 (standard)', 'MR60 (SEW)'],
    defaultValue: 'MR10 (standard)',
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Support',
    type: 'checkbox',
    name: 'Support',
    values: false,
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 450,
      maxValue: 3000,
      messages: {
        min: 'The minimum height is 450mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 3000mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Support',
    },
  },
  {
    field_name: 'Inner Side Guide',
    type: 'checkbox',
    name: 'Inner_Side_Guide',
    values: false,
    groupDependancy: 'Side Guide',
  },
  {
    field_name: 'Inner Side Guide Height',
    type: 'limit',
    name: 'Inner_Side_Guide_Height',
    groupDependancy: 'Side Guide',
    limit: {
      defaultValue: 100,
      minValue: 50,
      maxValue: 500,
      messages: {
        min: 'The minimum inner side guide height is 50mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum inner side guide height is 500mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Inner_Side_Guide',
    },
  },
  {
    field_name: 'Outer Side Guide',
    type: 'checkbox',
    name: 'Outer_Side_Guide',
    values: false,
    groupDependancy: 'Side Guide',
  },
  {
    field_name: 'Outer Side Guide Height',
    type: 'limit',
    name: 'Outer_Side_Guide_Height',
    groupDependancy: 'Side Guide',
    limit: {
      defaultValue: 100,
      minValue: 50,
      maxValue: 500,
      messages: {
        min: 'The minimum outer side guide height is 50mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum outer side guide height is 500mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Outer_Side_Guide',
    },
  },
];

export default model;
