import { loadExtension, setCamera } from './viewerConfigs';

const Autodesk = window.Autodesk;

export function launchViewer(
  urn,
  accessToken,
  setLoading,
  setProgressValue,
  model,
) {
  const options = {
    env: 'AutodeskProduction',
    getAccessToken: function (callback) {
      callback(accessToken, 3365);
    },
  };
  Autodesk.Viewing.Initializer(options, () => {
    const viewer = new Autodesk.Viewing.GuiViewer3D(
      document.getElementById('init_div'),
      { extensions: ['Autodesk.DocumentBrowser'] },
    );
    window.viewer = viewer;
    const documentId = 'urn:' + urn;

    viewer.start();

    const viewerStart = function () {
      Autodesk.Viewing.Document.load(
        documentId,
        onDocumentLoadSuccess,
        onDocumentLoadFailure,
      );
    };

    viewerStart();
    const onExtensionLoaded = (e) => {
      loadExtension(e, viewer);
    };

    viewer.addEventListener(
      Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      onExtensionLoaded,
    );

    function onDocumentLoadSuccess(doc) {
      console.log('onDocumentLoadSuccess');
      setProgressValue({
        valueStart: 0,
        valueEnd: 0,
        duration: 0,
      });
      setLoading(false);
      const viewables = doc.getRoot().getDefaultGeometry();
      if (viewables) {
        viewer.loadDocumentNode(doc, viewables).then(() => {
          setCamera(viewer, model);
        });
      }
    }

    function onDocumentLoadFailure(viewerErrorCode, viewerErrorMsg) {
      console.error(
        'onDocumentLoadFailure() - errorCode:' +
          viewerErrorCode +
          '\n- errorMessage:' +
          viewerErrorMsg,
      );
      if (viewerErrorCode === 9) {
        setProgressValue({
          valueStart: 98,
          valueEnd: 99,
          duration: 0,
        });
        setTimeout(() => {
          viewerStart();
        }, 10000);
      }
    }
  });
}
