import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Viewer from '../Components/Viewer/Viewer-Container';

const Routs = () => {
  return (
    <Routes>
      <Route path="/VEHO" element={<Viewer model="VEHO" />} />
      <Route path="/N60" element={<Viewer model="N60" />} />
      <Route path="/FR-40" element={<Viewer model="FR-40" />} />
      <Route path="/FR-12-20" element={<Viewer model="FR-12-20" />} />
      <Route path="/FR-20-40" element={<Viewer model="FR-20-40" />} />
      <Route path="/FR-20-20-M" element={<Viewer model="FR-20-20-M" />} />
      <Route path="/FR-40-Q" element={<Viewer model="FR-40-Q" />} />
      <Route path="/FR-30-60" element={<Viewer model="FR-30-60" />} />
      <Route path="/BK-20" element={<Viewer model="BK-20" />} />
      <Route path="/BK-30" element={<Viewer model="BK-30" />} />
      <Route path="/C83" element={<Viewer model="C83" />} />
      <Route path="/RD50" element={<Viewer model="RD50" />} />
      <Route path="/RD50C" element={<Viewer model="RD50C" />} />
      <Route path="/MBC10" element={<Viewer model="MBC10" />} />
      <Route path="/KDC" element={<Viewer model="KDC" />} />
      <Route path="/KDZ" element={<Viewer model="KDZ" />} />
      <Route path="/N114" element={<Viewer model="N114" />} />
      <Route path="/R100" element={<Viewer model="R100" />} />
      <Route path="/R150" element={<Viewer model="R150" />} />
      <Route path="/BMK" element={<Viewer model="BMK" />} />
      <Route path="*" element={<Navigate to="/VEHO" />} />
    </Routes>
  );
};

export default Routs;
