const model = [
  {
    field_name: 'Type',
    type: 'select',
    name: 'Type',
    values: ['Roller Drive', 'Drive Shaft'],
    defaultValue: 'Roller Drive',
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Width',
    type: 'select',
    name: 'Width',
    values: [410, 510, 610, 810, 1010],
    defaultValue: 510,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Angle',
    type: 'select',
    name: 'Angle',
    values: [30, 45, 60, 90, 120, 135, 150, 180],
    defaultValue: 90,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Support',
    type: 'checkbox',
    name: 'Support',
    values: false,
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 300,
      maxValue: 1500,
      messages: {
        min: 'The minimum height is 900mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 9000mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Support',
    },
  },
];

export default model;
