const model = [
  {
    field_name: 'Angle (deg)',
    type: 'limit',
    name: 'Angle',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 90,
      minValue: 24,
      maxValue: 180,
      messages: {
        min: 'The minimum angle is 24deg. The value will be automatically corrected to the minimum. ',
        max: 'The maximum angle is 180deg. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Belt Width',
    type: 'limit',
    name: 'Belt_Width',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 600,
      minValue: 200,
      maxValue: 1500,
      messages: {
        min: 'The minimum belt width is 200mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum belt width is 1500mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Outer Radius',
    type: 'limit',
    name: 'Outer_Radius',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 1200,
      minValue: 600,
      maxValue: 3500,
      messages: {
        min: 'The minimum outer radius is 600mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum outer radius is 3500mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Motor Type',
    type: 'select',
    name: 'Motor_type',
    values: ['MR10 (standard)', 'MR60 (SEW)'],
    defaultValue: 'MR10 (standard)',
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Counter Clockwise',
    type: 'checkbox',
    name: 'Counter_Clockwise',
    values: false,
    groupDependancy: 'Motor Position',
  },
  {
    field_name: 'Drive Unit Inside',
    type: 'checkbox',
    name: 'Drive_Unit_Inside',
    values: false,
    groupDependancy: 'Motor Position',
  },
  {
    field_name: 'Inner Side Guide',
    type: 'checkbox',
    name: 'Inner_Side_Guide',
    values: false,
    groupDependancy: 'Side Guide',
  },
  {
    field_name: 'Inner Side Guide Height',
    type: 'limit',
    name: 'Inner_Side_Guide_Height',
    groupDependancy: 'Side Guide',
    limit: {
      defaultValue: 100,
      minValue: 50,
      maxValue: 200,
      messages: {
        min: 'The minimum inner side guide height is 50mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum inner side guide height is 200mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Inner_Side_Guide',
    },
  },
  {
    field_name: 'Outer Side Guide',
    type: 'checkbox',
    name: 'Outer_Side_Guide',
    values: false,
    groupDependancy: 'Side Guide',
  },
  {
    field_name: 'Outer Side Guide Height',
    type: 'limit',
    name: 'Outer_Side_Guide_Height',
    groupDependancy: 'Side Guide',
    limit: {
      defaultValue: 100,
      minValue: 50,
      maxValue: 200,
      messages: {
        min: 'The minimum outer side guide height is 50mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum outer side guide height is 200mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Outer_Side_Guide',
    },
  },
];

export default model;
