import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Progress from '../../Progress/Progress';
import Styles from './Styles.module.css';

const LoadingText = () => {
  const [loadingText, setLoadingText] = useState('Preparing');

  useEffect(() => {
    const timerId = setInterval(() => {
      setLoadingText('This may take some time');
    }, 45000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div>
      <div className={Styles.pre}>
        <h1 style={{ color: '#3e98c7' }}>{loadingText}</h1>
        <div
          className={Styles.dotpulse}
          style={{ display: loadingText === 'Preparing' ? 'none' : '' }}
        ></div>
      </div>
      <div className={Styles.cancelButton}>
        <Button
          variant="dark"
          style={{ display: loadingText === 'Preparing' ? 'none' : '' }}
          onClick={() => window.location.reload()}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const Placeholder = ({ loading, progressValue }) => {
  return (
    <div
      className={Styles.progressContainer}
      style={{ zIndex: loading ? 1 : 0 }}
    >
      {loading && (
        <div className={Styles.progressBar}>
          <Progress progressValue={progressValue} />
          <LoadingText />
        </div>
      )}
    </div>
  );
};

export default Placeholder;
