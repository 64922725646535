import io from 'socket.io-client';
import { workItemFetch } from '../api/allRequests';

const { SERVER_HTTP_PROTOCOL, SERVER_HOST, SERVER_PORT } = process.env;

export const startWorkitem = (
  setProgressValue,
  parameters,
  model,
  setFileName,
  setUrn,
  setLoading,
  messages,
  setModalShow,
  setConnectionId,
  setMessages,
  connectionId,
  setErrorModal,
  setOrderListBool,
  adviceOffer,
) => {
  return function () {
    setProgressValue({ valueStart: 0, valueEnd: 55, duration: 20 });
    startConnection(function (connectionId) {
      const formData = new FormData();
      parameters.browerConnectionId = connectionId;
      formData.append('data', JSON.stringify(parameters));
      if (adviceOffer) {
        formData.append('adviceOffer', JSON.stringify(adviceOffer));
      }
      workItemFetch(
        model,
        formData,
        setFileName,
        setProgressValue,
        setUrn,
        setLoading,
        setErrorModal,
      );
    });
  };
  function startConnection(onReady) {
    if (Object.keys(messages).length !== 0) {
      setModalShow(true);
      if (messages.R100_inner_Radius) {
        return;
      }
    }
    const socket = io.connect(
      `${SERVER_HTTP_PROTOCOL}://${SERVER_HOST}:${SERVER_PORT}`,
      {
        transports: ['websocket'],
      },
    );
    setLoading(true);
    if (socket && socket.connected) {
      if (onReady) onReady(connectionId);
      return;
    }
    socket.on('connect', function () {
      onReady(socket.id);
      setConnectionId(socket.id);
    });
    socket.on('downloadReport', function (url) {
      console.log('<a href="' + url + '">Download report file here</a>');
    });

    socket.on('onComplete', function (message) {
      // console.log("message socket", message);
      // console.log("socket", socket);
      if (message) {
        setProgressValue({
          valueStart: 56,
          valueEnd: 99,
          duration: 2,
        });
        socket.disconnect();
        setTimeout(() => {
          setTimeout(() => {
            setOrderListBool(true);
            setUrn(message.urn);
          }, 1500);
        }, 0);
      }
    });
    socket.on('onFaild', function (message) {
      console.log('message socket', message);
      setModalShow(true);
      setMessages({
        error:
          'Unexpected problem: Connection with the Configurator was lost. Please wait a short while for the connection problem to be resolved before trying again.',
      });
    });
  }
};
