const model = [
  {
    field_name: 'Belt Width',
    type: 'limit',
    name: 'Beltwidth',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 600,
      minValue: 500,
      maxValue: 1100,
      messages: {
        min: 'The minimum belt width is 500mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum belt width is 1100mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Motor type',
    type: 'select',
    name: 'Motor_Type',
    groupDependancy: 'Dimensions',
    values: ['MR10 (standard)', 'MR60 (SEW)'],
    defaultValue: 'MR10 (standard)',
  },
  {
    field_name: 'Support',
    type: 'checkbox',
    name: 'Support',
    values: false,
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 450,
      maxValue: 3000,
      messages: {
        min: 'The minimum height is 450mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 3000mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Support',
    },
  },
];

export default model;
