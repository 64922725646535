import React, { useState, useEffect } from 'react';
import Progress from '../../../Progress/Progress';
import Styles from './Styles.module.css';

const LoadingText = () => {
  const [loadingText, setLoadingText] = useState('Preparing');

  useEffect(() => {
    const timerId = setInterval(() => {
      setLoadingText('This may take some time');
    }, 25000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div className={Styles.pre}>
      <h1 style={{ color: '#3e98c7' }}>{loadingText}</h1>
      <div
        className={Styles.dotpulse}
        style={{ display: loadingText === 'Preparing' ? 'none' : '' }}
      ></div>
    </div>
  );
};

const Placeholder = ({ progressValue }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div className={Styles.progressContainer}>
        <Progress progressValue={progressValue} />
      </div>
      <LoadingText />
    </div>
  );
};

export default Placeholder;
