import Styles from './styles.module.css';
import Placeholder from './Placeholder/Placeholder';

function Viewer ({ loading, progressValue }) {
  return (
    <div>
      <div id="init_div" className={Styles.init_div}></div>
      <Placeholder loading={loading} progressValue={progressValue} />
    </div>
  );
}

export default Viewer;
