const model = [
  {
    field_name: 'Length',
    type: 'select',
    name: 'Length',
    values: [100, 150, 200],
    defaultValue: 100,
    groupDependancy: 'Dimensions',
  },
];

export default model;
