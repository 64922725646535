const checkSelectType = (
  name,
  value,
  model,
  parameters,
  field_name,
  messages,
) => {
  const limitMessage = { ...messages };
  if (model === 'N114') {
    if (field_name === 'Support' && parameters.BW_Manual < 400) {
      if (value === 'LT42' || value === 'LT44') {
        // console.log('parameters.BW_Manual', parameters.BW_Manual);
        parameters.previous = { BW_Manual_previous: parameters.BW_Manual };
        parameters.BW_Manual = 400;
        limitMessage.BW_Manual =
          'The minimum belt width is 400mm. The value will be automatically corrected to the minimum. ';
      }
    } else {
      parameters.BW_Manual = parameters.previous?.BW_Manual_previous;
      delete limitMessage.BW_Manual;
    }
  }
  return {
    limitMessage,
    paramValue: {
      [name]: value,
      BW_Manual: parameters.BW_Manual,
    },
  };
};

export default checkSelectType;
