const model = [
  {
    field_name: 'Belt width',
    type: 'select',
    name: 'Width',
    values: [
      70, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000,
      1200,
    ],
    defaultValue: 500,
    groupDependancy: 'Dimensions',
    dependence: {
      dependenceName: 'BW_Manual_Input',
    },
  },
  {
    field_name: 'Belt width manual input',
    type: 'checkbox',
    values: false,
    name: 'BW_Manual_Input',
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Belt width manual input',
    type: 'limit',
    name: 'Width',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 500,
      minValue: 70,
      maxValue: 1200,
      messages: {
        min: 'The minimum length is 70mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 1200mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'BW_Manual_Input',
    },
  },
  {
    field_name: 'Length center to center',
    type: 'limit',
    name: 'Length',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 2000,
      minValue: 350,
      maxValue: 30000,
      messages: {
        min: 'The minimum length is 350mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 30000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  // {
  //   field_name: "Belt color",
  //   type: "select",
  //   name: "color",
  //   values: ["Green", "Black", "Blue", "Grey", "White", "orange"],
  // },
  // {
  //   field_name: "Direction",
  //   type: "select",
  //   name: "Direction",
  //   values: ["Pull", "idirectional", "Push"],
  // },
  {
    field_name: 'Motor type',
    type: 'select',
    name: 'Motor_Type',
    values: ['MR10 (standard)', 'MR60 (SEW)', 'MR70 Drummotor'],
    defaultValue: 'MR10 (standard)',
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Motor position',
    type: 'select',
    name: 'Drive_unit',
    values: ['D10 Left', 'D10 Right', 'D20 Left', 'D20 Right', 'D30 Middle'],
    groupDependancy: 'Drive Unit',
    defaultValue: 'D10 Left',
  },
  // {
  //   field_name: "Horizontal / Vertical",
  //   type: "select",
  //   name: "Motor_Horizontal_or_Vertical",
  //   values: ["Horizontal", "Vertical Down", "Vertical Up"],
  // },
  {
    field_name: 'Option input side',
    type: 'select',
    name: 'Options_Input_Side',
    values: ['None', 'Shortened Plate', 'Transfer Roll', 'Fixed Knife Edge'],
    defaultValue: 'None',
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Option output side',
    type: 'select',
    name: 'Options_Output_Side',
    values: ['None', 'Shortened Plate', 'Transfer Roll'],
    defaultValue: 'None',
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Support',
    type: 'select',
    name: 'Support',
    values: ['None', 'LT22', 'LT24', 'LT30', 'LT31'],
    defaultValue: 'None',
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 900,
      maxValue: 9000,
      messages: {
        min: 'The minimum height is 900mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 9000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Feet / Wheel',
    type: 'select',
    name: 'Feet',
    values: ['Feet', 'Feet with Bracket', 'Wheel D75', 'Wheel D100'],
    defaultValue: 'Feet',
    groupDependancy: 'Support',
  },
  {
    field_name: 'Guide bracket',
    type: 'select',
    name: 'Side_Bracket',
    values: ['None', 'GMB10', 'GMB20', 'GMB30'],
    defaultValue: 'None',
    groupDependancy: 'Side Guide',
  },
  {
    field_name: 'Guide type',
    type: 'select',
    name: 'Guide_Type',
    values: [
      'None',
      'GR50: 18mm, Alu, Spherical UHMPE',
      'GR55: 18mm, Alu, Flat UHMPE',
      'GR60: 40mm, Alu, Flat UHMPE',
    ],
    defaultValue: 'None',
    groupDependancy: 'Side Guide',
  },
  // {
  //   field_name: "Show arrow on belt",
  //   type: "checkbox",
  //   name: "Arrow",
  //   values: true,
  // },
];

export default model;
