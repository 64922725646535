const model = [
  {
    field_name: 'Length',
    type: 'select',
    name: 'Length',
    values: [200, 300, 400, 500, 600, 700, 800, 900, 1000],
    defaultValue: 200,
    groupDependancy: 'Dimensions',
    dependence: {
      dependenceName: 'Length_Manual_Input',
    },
  },
  {
    field_name: 'Length Manual Input',
    type: 'checkbox',
    name: 'Length_Manual_Input',
    values: false,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Length Manual',
    type: 'limit',
    name: 'Length',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 200,
      minValue: 200,
      maxValue: 1000,
      messages: {
        min: 'The minimum length is 200mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 1000mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Length_Manual_Input',
    },
  },
];

export default model;
