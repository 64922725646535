const model = [
  {
    field_name: 'Inside Radius',
    type: 'readOnly',
    name: 'Inside_radius',
    value: 600,
  },
  {
    field_name: 'Belt Width',
    type: 'select',
    name: 'Beltwidth',
    values: [400, 600, 800, 1000],
    defaultValue: 600,
  },
  {
    field_name: 'Angle (deg)',
    type: 'select',
    name: 'Angle',
    values: [30, 45, 60, 90, 180],
    defaultValue: 90,
  },
  {
    field_name: 'Direction',
    type: 'select',
    name: 'Direction',
    values: ['CW', 'CCW'],
    defaultValue: 'CW',
  },
  {
    field_name: 'Drive Position',
    type: 'select',
    name: 'Drive_position',
    values: ['Outside', 'Inside'],
    defaultValue: 'Outside',
  },
  {
    field_name: 'Motor Type',
    type: 'select',
    name: 'Motor_type',
    values: ['MR10 (standard)', 'MR60 (SEW)'],
    defaultValue: 'MR10 (standard)',
  },
];

export default model;
