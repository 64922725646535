import N60 from './N60';
import VEHO from './VEHO.js';
import FR_40_Q from './FR-40-Q.js';
import FR_40 from './FR-40.js';
import FR_12_20 from './FR-12-20.js';
import FR_20_40 from './FR-20-40.js';
import FR_20_20_M from './FR-20-20-M.js';
import FR_30_60 from './FR-30-60.js';
import BK_20 from './BK-20.js';
import BK_30 from './BK-30.js';
import C83 from './C83.js';
import RD50C from './RD50C.js';
import RD50 from './RD50.js';
import MBC10 from './MBC10.js';
import KDC from './KDC.js';
import KDZ from './KDZ.js';
import N114 from './N114.js';
import R100 from './R100.js';
import R150 from './R150.js';
import BMK from './BMK.js';

const parameters = {
  N60,
  VEHO,
  'FR-40': FR_40,
  'FR-12-20': FR_12_20,
  'FR-20-40': FR_20_40,
  'FR-20-20-M': FR_20_20_M,
  'FR-40-Q': FR_40_Q,
  'FR-30-60': FR_30_60,
  'BK-20': BK_20,
  'BK-30': BK_30,
  C83,
  RD50C,
  RD50,
  MBC10,
  KDC,
  KDZ,
  N114,
  R100,
  R150,
  BMK,
};

export default parameters;
