const model = [
  {
    field_name: 'Width',
    type: 'select',
    name: 'Width',
    values: [255, 340, 425, 510, 595],
    defaultValue: 425,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Length center to center',
    type: 'limit',
    name: 'Length',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 1500,
      minValue: 1290,
      maxValue: 15000,
      messages: {
        min: 'The minimum length is 1290mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 15000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Drive position',
    type: 'select',
    name: 'Drive_Position',
    values: ['Left', 'Right'],
    defaultValue: 'Left',
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Drive Cover',
    type: 'checkbox',
    name: 'Drive_Cover',
    values: false,
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Roller Input',
    type: 'checkbox',
    name: 'Roller_Input',
    values: false,
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Roller Output',
    type: 'checkbox',
    name: 'Roller_Output',
    values: false,
    groupDependancy: 'Drive Unit',
  },
  {
    field_name: 'Support',
    type: 'checkbox',
    name: 'Support',
    values: false,
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 400,
      maxValue: 2500,
      messages: {
        min: 'The minimum height is 400mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 2500mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Support',
    },
  },
];

export default model;
