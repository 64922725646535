const model = [
  {
    field_name: 'Housing Width',
    type: 'select',
    name: 'Housing_Width',
    values: [60],
    defaultValue: 60,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Length',
    type: 'select',
    name: 'Length',
    values: [
      300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600,
    ],
    defaultValue: 400,
    groupDependancy: 'Dimensions',
    dependence: {
      dependenceName: 'Length_Manual_Input',
    },
  },
  {
    field_name: 'Length Manual Input',
    type: 'checkbox',
    name: 'Length_Manual_Input',
    values: false,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Length Manual',
    type: 'limit',
    name: 'Length',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 400,
      minValue: 300,
      maxValue: 1600,
      messages: {
        min: 'The minimum length is 300mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 16000mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Length_Manual_Input',
    },
  },
];

export default model;
