import React, { useState, useContext } from 'react';
import { Mycontext } from '../../Context/context';
import Styles from './styles.module.css';
import InputsChange from './content/InputsChange';
import OrderList from './content/OrderList';
import Convert from './content/Convert';
import Modal from '../Modal/Modal';
import ErrorModal from '../Modal/ErrorModal';
import checkLimitType from '../../utils/checkLimitType';
import checkSelectType from '../../utils/checkSelectType';
import { startWorkitem } from '../../utils/configureModel';

const Sidebar = () => {
  const [isOpenDownload, setOpenDownload] = useState(true);
  const [orderListBool, setOrderListBool] = useState(true);
  const {
    parameters,
    setParameters,
    setUrn,
    setLoading,
    loading,
    model,
    urn,
    setProgressValue,
    setFileName,
    isOpenErrorModal,
    setErrorModal,
  } = useContext(Mycontext);

  const [connectionId, setConnectionId] = useState('');
  const [messages, setMessages] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [openGroup, setOpenGroup] = useState({});
  const isOpenInputs = orderListBool && isOpenDownload;

  // console.log('messages', messages);
  // console.log('parameters', parameters);

  const handleChange = (e, type, field_name, limit) => {
    const { name, value } = e.target;
    if (type === 'limit') {
      const { limitMessage, paramValue } = checkLimitType(
        name,
        value,
        limit,
        model,
        messages,
        parameters,
        field_name,
      );

      setMessages(limitMessage);
      setParameters({ ...parameters, ...paramValue, inputValueBool: false });
    } else if (type === 'select') {
      const { limitMessage, paramValue } = checkSelectType(
        name,
        value,
        model,
        parameters,
        field_name,
        messages,
      );
      setMessages(limitMessage);
      setParameters({ ...parameters, ...paramValue, inputValueBool: false });
    } else {
      const { checked } = e.target;
      setParameters({
        ...parameters,
        [e.target.name]:
          e.target.type === 'checkbox'
            ? checked
            : isNaN(value)
              ? value
              : Number(value),
      });
    }
  };
  // console.log(parameters, '=----');
  const startConfiguration = (adviceOffer) =>
    startWorkitem(
      setProgressValue,
      parameters,
      model,
      setFileName,
      setUrn,
      setLoading,
      messages,
      setModalShow,
      setConnectionId,
      setMessages,
      connectionId,
      setErrorModal,
      setOrderListBool,
      adviceOffer,
    );

  return (
    <div className={Styles.sidebar}>
      {isOpenErrorModal && <ErrorModal />}
      <Modal
        modalShow={modalShow}
        setModalShow={setModalShow}
        setMessages={setMessages}
        messages={messages}
        parameters={parameters}
        setParameters={setParameters}
      />
      <div className={Styles.content}>
        {isOpenInputs && (
          <InputsChange
            parameters={parameters}
            setParameters={setParameters}
            handleChange={handleChange}
            loading={loading}
            setOpenDownload={setOpenDownload}
            isOpenInputs={isOpenInputs}
            setOrderListBool={setOrderListBool}
            openGroup={openGroup}
            setOpenGroup={setOpenGroup}
            startConfiguration={startConfiguration}
          />
        )}
        {isOpenDownload || (
          <Convert
            setOpenDownload={setOpenDownload}
            urn={urn}
            setErrorModal={setErrorModal}
          />
        )}
        {orderListBool || (
          <OrderList
            setOrderListBool={setOrderListBool}
            model_name={model}
            parameters={parameters}
            setErrorModal={setErrorModal}
            startConfiguration={startConfiguration}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
