const model = [
  {
    field_name: 'Type',
    type: 'select',
    name: 'Type',
    values: ['Roller Drive', 'Drive Shaft'],
    defaultValue: 'Roller Drive',
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Length',
    type: 'limit',
    name: 'Length',
    groupDependancy: 'Dimensions',
    limit: {
      defaultValue: 2000,
      minValue: 500,
      maxValue: 100000,
      messages: {
        min: 'The minimum length is 500mm. The value will be automatically corrected to the minimum. ',
        max: 'The maximum length is 100000mm. The value will be automatically corrected to the maximum.',
      },
    },
  },
  {
    field_name: 'Width',
    type: 'select',
    name: 'Width',
    groupDependancy: 'Dimensions',
    values: [
      310, 410, 510, 610, 710, 810, 910, 1010, 1110, 1210, 1310, 1410, 1510,
    ],
    defaultValue: 510,
  },
  {
    field_name: 'Profile',
    type: 'select',
    name: 'Profile_choice',
    values: ['Low - Low', 'Low - High', 'High - Low', 'High - High'],
    defaultValue: 'Low - Low',
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Pitch',
    type: 'select',
    name: 'Pitch',
    values: [55, 75, 100, 125],
    defaultValue: 100,
    groupDependancy: 'Dimensions',
  },
  {
    field_name: 'Support',
    type: 'select',
    name: 'Support',
    values: ['None', 'LT22', 'LT42'],
    defaultValue: 'None',
    groupDependancy: 'Support',
  },
  {
    field_name: 'Height',
    type: 'limit',
    name: 'Height',
    groupDependancy: 'Support',
    limit: {
      defaultValue: 900,
      minValue: 300,
      maxValue: 1500,
      messages: {
        min: 'The minimum height is 900mm. The value will be automatically corrected to the minimum.',
        max: 'The maximum height is 9000mm. The value will be automatically corrected to the maximum.',
      },
    },
    dependence: {
      dependenceName: 'Support',
    },
  },
];

export default model;
