import React, { useEffect, useContext, useState } from 'react';
import { Mycontext } from '../../Context/context';
import { initializeFetch, getAuthToken } from '../../api/allRequests';
import { launchViewer } from '../../utils/lunchViewer';

import Viewer from './Viewer';

function ViewerContainer (props) {
  const [accessToken, setAccessToken] = useState('');

  const {
    urn,
    setUrn,
    setLoading,
    setModel,
    model,
    setFileName,
    loading,
    progressValue,
    setProgressValue,
    setErrorModal,
  } = useContext(Mycontext);

  useEffect(() => {
    if (accessToken) {
      initializeFetch(accessToken, model, setUrn, setFileName, setErrorModal);
    }
  }, [accessToken]);

  useEffect(() => {
    if (urn) {
      launchViewer(urn, accessToken, setLoading, setProgressValue, model, setErrorModal);
    }
  }, [urn, model]);
  useEffect(async () => {
    setModel(props.model);
    getAuthToken(setAccessToken);
  }, []);

  return (
    <Viewer
      loading={loading}
      progressValue={progressValue}
    />
  );
}

export default ViewerContainer;
